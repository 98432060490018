import React, { Component } from "react";
import PrivacyHeader from "./components/PrivacyHeader";
import { Table } from "react-bootstrap";
import axios from "axios";
import { unflatten } from "flat";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const unflattenTemplateItems = (templateItems) => {
  let content = {};
  content = templateItems.reduce(
    (agg, curr) => ({
      ...agg,
      [curr.key]: {
        value: curr.value?.map((item) => item.content),
        type: curr.type,
      },
    }),
    {}
  );
  content = unflatten(content);
  return content.content;
};

export default class Privacy extends Component {
  state = {
    privacyData: [],
    heading: null,
    subHeading: null,
  };
  getPrivacy = () => {
    axios
      .get(`${process.env.GATSBY_STRAPI_BASE_URL}/api/junio-tandcs?populate=content.value`)
      .then((res) => {
        const privacyData =  res.data.data?.find(item => item?.attributes.type == "PRIVACY")
        this.setState(
          {
            heading: privacyData.attributes.heading,
            subHeading: privacyData.attributes.subHeading,
            privacyData: unflattenTemplateItems(privacyData.attributes.content),
          }
        );
      }).catch(() => {});
  };
  componentDidMount() {
    this.getPrivacy();
  }
  render() {
    return (
      <React.Fragment>
        <PrivacyHeader />
        <div className="pnt">
          <h2>{this.state.heading}</h2>
          <h4>{this.state.subHeading}</h4>
          {this.state.privacyData.length > 0 &&
            this.state.privacyData.map((term) => {
              if (term.value) {
                return <ReactMarkdown className="markdown-custom">{term.value[0]}</ReactMarkdown>;
              }
              if (term.tableRow) {
                return (
                  <Table className="privacy-table">
                    <thead>
                      <tr className="markdown-tr">
                        {term.tableRow[0].value.map((heading) => {
                          return (
                            <th className="markdown-td">
                              <ReactMarkdown className="markdown-heading">
                                {heading}
                              </ReactMarkdown>
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {term.tableRow.map((row, index) => {
                        if (index !== 0) {
                          return (
                            <tr className="markdown-tr">
                              {row.value.map((item) => {
                                return (
                                  <td className="markdown-td">
                                    <ReactMarkdown>{item}</ReactMarkdown>
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </Table>
                );
              }
            })}
        </div>
      </React.Fragment>
    );
  }
}